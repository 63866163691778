import server from '../apis/server';
import { serialize } from 'object-to-formdata';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import snakeCaseKeys from 'snakecase-keys';

export const actionTypes = {
  FETCH_CLIPPINGS: 'FETCH_CLIPPINGS',
  FETCH_CLIPPING: 'FETCH_CLIPPING',
  FETCH_CAMPAIGNS_CLIPPINGS: 'FETCH_CAMPAIGNS_CLIPPINGS',
}

export const fetchClippings = () => async (dispatch, getState) => {
  const { companyStringId, projectStringId } = getState().auth.user;
  await server.get(`/clipping/list/${projectStringId}/${companyStringId}`)
    .then(({ data }) => {
      const clippingsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CLIPPINGS, payload: clippingsResult })
    })
};

export const fetchClippingsByDate = ({ start, end }) => async (dispatch, getState) => {
  const { companyStringId, projectStringId } = getState().auth.user;
  await server.get(`/clipping/list/${projectStringId}/${companyStringId}?start=${start}&end=${end}`)
    .then(({ data }) => {
      const clippingsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CLIPPINGS, payload: clippingsResult })
    })
};

export const fetchClippingsByDateRange = ({ startDate, endDate }) => async (dispatch, getState) => {
  const { companyStringId, projectStringId } = getState().auth.user;
  await server.get(`/clipping/list/${projectStringId}/${companyStringId}?date_from=${startDate}&date_to=${endDate}}`)
    .then(({ data }) => {
      const campaignsClippingsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CAMPAIGNS_CLIPPINGS, payload: campaignsClippingsResult })
    })
};

export const fetchRecentClippings = ({ limit }) => async (dispatch, getState) => {
  const { companyStringId, projectStringId } = getState().auth.user;
  await server.get(`/clipping/recent/${projectStringId}/${companyStringId}/${limit}`)
    .then(({ data }) => {
      const clippingsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CLIPPINGS, payload: clippingsResult })
    })
};

export const fetchClipping = ({ clippingStringId }) => async (dispatch) => {
  await server.get(`/clipping/item/${clippingStringId}`)
    .then(({ data }) => {
      const clippingResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CLIPPING, payload: clippingResult })
    })
};

export const createClipping = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.post('/clipping/create', serialize(formattedData));
};

export const updateClipping = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.patch('/clipping/update', serialize(formattedData));
};

export const deleteClipping = ({ clippingStringId }) => async () => {
  return await server.delete(`/clipping/item/${clippingStringId}`);
}