import server from '../apis/server';
import { serialize } from 'object-to-formdata';
import snakeCaseKeys from 'snakecase-keys';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const actionTypes = {
  FETCH_PLANS: 'FETCH_PLANS',
  FETCH_PLAN: 'FETCH_PLAN',
}

export const fetchPlans = ({ start, end }) => async (dispatch, getState) => {
  const { companyStringId } = getState().auth.user;
  await server.get(`/plan/list/${companyStringId}?start=${start}&end=${end}`)
    .then(({ data }) => {
      const plansResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_PLANS, payload: plansResult })
    }) 
};

export const fetchPlan = ({ planStringId }) => async (dispatch) => {
  await server.get(`/plan/item/${planStringId}`)
    .then(({ data }) => {
      const planResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_PLAN, payload: planResult })
    })
};

export const fetchRecentPlans = ({ limit }) => async (dispatch, getState) => {
  const { companyStringId } = getState().auth.user;
  await server.get(`/plan/recent/${companyStringId}/${limit}`)
    .then(({ data }) => {
      const plansResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_PLANS, payload: plansResult })
    }) 
};

export const createPlan = (params) => async (dispatch, getState) => {
  const { companyId } = getState().auth.user;
  params.companyId = companyId;
  const formattedData = snakeCaseKeys(params);
  return await server.post('/plan/create', serialize(formattedData));
};

export const updatePlan = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.patch('/plan/update', serialize(formattedData));
};

export const deletePlan = ({ planStringId }) => async () => {
  return await server.delete(`/plan/item/${planStringId}`);
};