import server from '../apis/server';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const actionTypes = {
  FETCH_PROJECTS: 'FETCH_PROJECTS',
}

export const getProjects = async () => {
  let projectsResult = [];
  await server.get(`/project/list`)
    .then(({ data }) => {
      projectsResult = camelcaseKeysDeep(data.data);
    })
  return projectsResult;
};

export const fetchProjects = () => async (dispatch) => {
  await server.get(`/project/list`)
    .then(({ data }) => {
      const projectsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_PROJECTS, payload: projectsResult })
    })
};