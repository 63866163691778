import React from 'react';

const Loader = () => {
  return (
    <button type="button" className="btn btn-outline-default spinner spinner-darker-primary spinner-left mr-3">
      Please wait...
    </button>
  );
}

export default Loader;