import { actionTypes } from "../actions/channels";

const variable = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_UOM_LIST:
      return action.payload;
    default:
      return state;
  }
};

export default variable;