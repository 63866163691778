import axios from 'axios';
import { logMsg } from '../../helper';

const serverNoAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

serverNoAuth.interceptors.response.use(response => response
  , error => {
    error.appErrorCode = logMsg(error);
    return Promise.reject(error);
  });

export default serverNoAuth;
