import { toast } from 'react-toastify';
import moment from 'moment';

export const getEmptyStringIfNullOrUndefied = (input) => {
  return input ? input : '';
}

export const getZeroIfNullOrUndefied = (input) => {
  return input ? input : 0;
}

export const getEmptyArrayIfNullOrUndefied = (input) => {
  return input ? input : [];
}

export const getTodayIfNullOrUndefied = (input) => {
  return input ? moment(input).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
}

export const getDateFromTodayIfNullOrUndefied = (input, days) => {
  return input ? moment(input).format('YYYY-MM-DD') : moment().add(days, 'days').format('YYYY-MM-DD');
}

export const getEmptyObjectIfNullOrUndefied = (input) => {
  return input ? input : {};
}

export const numberWithCommas = (x) => {
  if (isNaN(x))
    return null;

  x = Number(x).toFixed(2);
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const logMsg = (error) => {
    console.log(error);
    const appErrorCode = moment().unix();
    let errorMsg = '';
    if (typeof error === 'object') {
      errorMsg = JSON.stringify(error);
    } else if (typeof error === 'string') {
      errorMsg = error;
    }

    console.log(errorMsg);

    // distribute log to rollbar
    window.onerror(`${appErrorCode}: ${errorMsg}}`, window.location.href);
    return appErrorCode;
}

export const getSentimentColor = (sentimentCode) => {
  return sentimentCode === 'G' ? 'sentiment-positive-color' : sentimentCode === 'N' ? 'sentiment-normal-color' : 'sentiment-negative-color';
}

export const getSentiment = (sentimentCode) => {
  return sentimentCode === 'G' ? 'Positive' : sentimentCode === 'N' ? 'Normal' : 'Negative';
}

export const getSelectList = (list, valueName, labelName) => {
  return list.map(item => ({ value: item[valueName], label: item[labelName], ...item }));
}

export const getSelectedItem = (list, selectedValue, valueName, labelName) => {
  if (!list || list.length === 0) {
    return {};
  }

  const selectedItem = list.find(item => item[valueName] === selectedValue);

  return selectedItem ? {
    value: selectedItem[valueName], label: selectedItem[labelName], ...selectedItem,
  } : {};
}

export const getBoolean = (input) =>  {
  return input === true || input === 'true' || input === '1' || input === 1 ? true : false;
}

export const convertBooleanToInt = (input) => {
  return input === true ? 1 : 0;
}

export const limitString = (input, limit) => {
  return input && input.length > limit ? input.substring(0, limit) + '...' : input;
}

export const successToast = (message) => {
  toast.success(message, {
    position: "top-right",
    bodyClassName: "bg-success",
    className: "bg-success",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const errorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    bodyClassName: "bg-danger",
    className: "bg-danger",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const infoToast = (message) => {
  toast.info(message, {
    position: "top-right",
    bodyClassName: "bg-info",
    className: "bg-info",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export const warningToast = (message) => {
  toast.error(message, {
    position: "top-center",
    bodyClassName: "bg-warning",
    className: "bg-warning",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}