import React from 'react';
import {connect} from 'react-redux';
import {fetchReportByGuest} from '../../../redux/actions/reports';
import SummaryReport from '../SummaryReportPreview/components/SummaryReport';
import {logMsg, errorToast} from '../../../helper';
import MessageCard from '../../components/MessageCard';
import Loader from '../../components/Loader';
import {NavLink} from 'react-router-dom';

class SummaryReportPreviewGuest extends React.Component {
  state = {
    isLoading: true,
    openEmailForm: false,
  };

  componentDidMount = async () => {
    const analyticsStringId = this.props.match.params.id;
    try {
      await this.props.fetchReportByGuest ({
        analyticsStringId: analyticsStringId,
      });
    } catch (error) {
      const appErrorCode = logMsg (error);
      errorToast ('Something went wrong. Trace ID: ' + appErrorCode);
    } finally {
      this.setState ({isLoading: false});
    }
  };

  render () {
    if (this.state.isLoading) {
      return <div className="container"><Loader /></div>;
    }

    if (!this.props.report) {
      return (
        <MessageCard
          color="danger"
          message="Cannot find what you are looking for."
        />
      );
    }

    return (
      <div className="container mt-10">
        <div className="text-right pr-5">
          <NavLink to="/main-page" className="font-size-h4">
            Go to Main Page
          </NavLink>
        </div>
        <SummaryReport report={this.props.report} userRoleCode="CLI" CopyReportEnabled={false} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    report: state.report,
  };
};

const actionCreators = {
  fetchReportByGuest,
};

export default connect (mapStateToProps, actionCreators) (
  SummaryReportPreviewGuest
);
