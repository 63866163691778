import server from '../apis/server';
import { serialize } from 'object-to-formdata';
import snakeCaseKeys from 'snakecase-keys';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const actionTypes = {
  FETCH_CHANNELS: 'FETCH_CHANNELS',
  FETCH_CHANNEL_TYPES: 'FETCH_CHANNEL_TYPES',
  FETCH_CHANNEL: 'FETCH_CHANNEL',
  FETCH_UOM_LIST: 'FETCH_UOM_LIST',
}

export const fetchChannels = () => async (dispatch) => {
  await server.get(`/channel/list`)
    .then(({ data }) => {
      const channelsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CHANNELS, payload: channelsResult })
    })
};

export const fetchChannelTypes = () => async (dispatch) => {
  await server.get(`/channel/type/list`)
    .then(({ data }) => {
      const channelTypesResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CHANNEL_TYPES, payload: channelTypesResult })
    })
};

export const fetchChannel = ({ channelStringId }) => async (dispatch) => {
  await server.get(`/channel/item/${channelStringId}`)
    .then(({ data }) => {
      const channelResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CHANNEL, payload: channelResult })
    })
};

export const createChannel = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.post('/channel/create', serialize(formattedData));
};

export const updateChannel = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.patch('/channel/update', serialize(formattedData));
};

export const deleteChannel = ({ channelStringId }) => async () => {
  return await server.delete(`/channel/item/${channelStringId}`);
};

export const fetchUomList = () => async (dispatch) => {
  await server.get(`/channel/uom/list`)
    .then(({ data }) => {
      const uomListResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_UOM_LIST, payload: uomListResult })
    })
};
