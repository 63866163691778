import React from 'react';
import CopySummaryReport from '../../../components/CopySummaryReport';
import ClippingPreview from '../../../components/ClippingPreview';
import SentimentLabel from '../../../components/SentimentLabel';
import moment from 'moment';

const SummaryReport = ({children, report, userRoleCode, CopyReportEnabled=true}) => {
  const {
    analyticsStringId,
    analyticsDate,
    analyticsEmailDestCcList,
    analyticsEmailDestToList,
    analyticsSummary,
    sentimentName,
    analyticsTopic,
    analyzedUserName,
    clippings,
  } = report;

  const days = moment ().diff (moment (analyticsDate), 'days');

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label font-size-h2">
                  <img
                    className="mr-2"
                    src={`/images/SEARCHING.png`}
                    alt=""
                    width={25}
                  />
                  {analyticsTopic}
                </h3>
                <SentimentLabel sentimentName={sentimentName} />
              </div>
              {children}
            </div>
            <div className="card-body">
              <div className="d-flex flex-column flex-md-row flex-lg-column flex-xxl-row justify-content-between">
                <div className="d-flex align-items-center">
                  <span className="symbol symbol-50 mr-4" />
                  <div className="d-flex flex-column flex-grow-1 flex-wrap mr-2">
                    <div className="d-flex">
                      {userRoleCode !== 'CLI' &&
                        <a
                          href="/"
                          className="font-size-lg font-weight-bolder text-dark-75 text-hover-primary mr-2"
                        >
                          {analyzedUserName}
                        </a>}
                      <div className="font-weight-bold text-muted">
                        <span className="label label-success label-dot mr-2" />
                        {days > 0 ? days + ' days ago' : 'Today'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex my-2 my-xxl-0 align-items-md-center align-items-lg-start align-items-xxl-center flex-column flex-md-row flex-lg-column flex-xxl-row">
                  <div className="font-weight-bold text-muted mx-2">
                    {moment (analyticsDate).format ('LLL')}
                  </div>
                  <div
                    className="d-flex align-items-center flex-wrap flex-xxl-nowrap"
                    data-inbox="toolbar"
                  >
                    {CopyReportEnabled && <CopySummaryReport analyticsStringId={analyticsStringId} />}
                  </div>
                </div>
              </div>
              <div className="pb-10" />
              <div className="row">
                <div className="col-sm-12">
                  <div className="py-3 toggle-off-item">
                    <div dangerouslySetInnerHTML={{__html: analyticsSummary}} />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card card-custom card-shadowless bg-light-primary py-5 px-5 mb-5">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="font-weight-bold mr-2">Email:</span>
                      <span className="text-primary" style={{ wordBreak: "break-word" }}>
                        {analyticsEmailDestToList.replaceAll(",", ", ")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="font-weight-bold mr-2">
                        Email CC:
                      </span>
                      <span className="text-primary" style={{ wordBreak: "break-word" }}>
                        {analyticsEmailDestCcList.replaceAll(",", ", ")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {clippings.map ((clipping, key) => {
          return (
            <div className="col-md-6" key={key}>
              <ClippingPreview
                key={key}
                userRoleCode={userRoleCode}
                clipping={clipping}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SummaryReport;
