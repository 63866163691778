import React from 'react';
import {connect} from 'react-redux';
import {
  fetchUser,
  updateUser,
  updatePassword,
} from '../../../redux/actions/user';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {logMsg, successToast, errorToast} from '../../../helper';
import Loader from '../../components/Loader';

class Account extends React.Component {
  state = {
    showChangingPasswordForm: false,
    isLoading: true,
  };

  componentDidMount = async () => {
    this.props
      .fetchUser ()
      .catch (error => {
        const appErrorCode = logMsg (error);
        errorToast ('Something went wrong. Trace ID: ' + appErrorCode);
      })
      .finally (() => this.setState ({isLoading: false}));
  };

  updateShowChangingPasswordForm = status => {
    this.setState ({
      showChangingPasswordForm: status,
    });
  };

  render () {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const {
      companyId,
      companyName,
      companyStringId,
      email,
      emailVerified,
      emailPrimary,
      name,
      systemUserId,
      userId,
      userStringId,
      userRoleName,
      userRoleCode,
    } = this.props.user;

    const initialValues = {
      companyId: companyId,
      companyName: companyName,
      companyStringId: companyStringId,
      email: email,
      emailVerified: emailVerified,
      emailPrimary: emailPrimary,
      name: name,
      systemUserId: systemUserId,
      userId: userId,
      userStringId: userStringId,
      userRoleName: userRoleName,
      password: '',
      c_password: '',
    };

    return (
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div
              className="col-md-9"
              style={{
                display: this.state.showChangingPasswordForm ? 'none' : 'block',
              }}
            >
              <div className="card card-custom gutter-b example example-compact">
                <div className="card-header">
                  <h3 className="card-title">Edit Account</h3>
                  <div className="card-toolbar">
                    <div className="example-tools justify-content-center">
                      <button
                        type="button"
                        className="btn custom-secondary-bg btn-sm"
                        onClick={() =>
                          this.updateShowChangingPasswordForm (true)}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={async values => {
                    let submittingValues = values;

                    await this.props
                      .updateUser (submittingValues)
                      .then (response => {
                        successToast (response.data.message);
                        this.props.fetchUser ();
                      })
                      .catch (error => {
                        const appErrorCode = logMsg (error);
                        errorToast (
                          'Something went wrong. Trace ID: ' + appErrorCode
                        );
                      });
                  }}
                  validationSchema={Yup.object ().shape ({
                    email: Yup.string ().required ('Required'),
                    userRoleName: Yup.string ().required ('Required'),
                    name: Yup.string ().required ('Required'),
                  })}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props;

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="card-body">
                          <div className="form-group mb-8">
                            <div
                              className="alert alert-custom alert-light"
                              role="alert"
                            >
                              <div className="alert-icon">
                                <i className="flaticon2-writing icon-xl" />
                              </div>
                              <div className="alert-text">
                                The form is for users to update profile.
                              </div>
                            </div>
                          </div>
                          {userRoleCode === 'CLI'
                            ? <div className="form-group">
                                <label>
                                  Company
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={companyName}
                                  disabled
                                />
                              </div>
                            : null}

                          <div className="form-group">
                            <label>
                              Email
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                value={email}
                                disabled
                              />
                              {/* <div className="input-group-append">
                                <span className="input-group-text">
                                  <label className="checkbox checkbox-inline checkbox-primary">
                                    <input
                                      type="checkbox"
                                      checked={emailVerified ? 'checked' : null}
                                      readOnly
                                    />
                                    <span />
                                  </label>
                                  <span className="ml-1">
                                    {emailVerified ? 'Verified' : 'Unverified'}
                                  </span>
                                </span>
                              </div> */}
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="exampleSelect1">
                              Role
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={userRoleName}
                              disabled
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Name
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              id="name"
                              type="text"
                              value={values.name}
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter name..."
                            />
                            {errors.name &&
                              touched.name &&
                              <span className="form-text text-danger">
                                {errors.name}
                              </span>}
                          </div>
                          <div className="form-group">
                            <label>
                              Primary Email
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              name="emailPrimary"
                              type="email"
                              value={values.emailPrimary}
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter primary email..."
                            />
                            {errors.emailPrimary &&
                              touched.emailPrimary &&
                              <span className="form-text text-danger">
                                {errors.emailPrimary}
                              </span>}
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn custom-primary-bg mr-2"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                display: !this.state.showChangingPasswordForm
                  ? 'none'
                  : 'block',
              }}
            >
              <div className="card card-custom gutter-b example example-compact">
                <div className="card-header">
                  <h3 className="card-title">Change Password</h3>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={async values => {
                    let submittingValues = values;

                    await this.props
                      .updatePassword (submittingValues)
                      .then (response => {
                        this.props.fetchUser ();
                        successToast ('Please login using new password');
                      })
                      .catch (error => {
                        const appErrorCode = logMsg (error);
                        errorToast (
                          'Something went wrong. Trace ID: ' + appErrorCode
                        );
                      });
                  }}
                  validationSchema={Yup.object ().shape ({
                    password: Yup.string ().required ('Required'),
                    c_password: Yup.string ()
                      .required ('Required')
                      .when ('password', {
                        is: val => (val && val.length > 0 ? true : false),
                        then: Yup.string ().oneOf (
                          [Yup.ref ('password')],
                          'Both password need to be the same'
                        ),
                      }),
                  })}
                >
                  {props => {
                    const {
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    } = props;

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="card-body">
                          <div className="form-group mb-8">
                            <div
                              className="alert alert-custom alert-default"
                              role="alert"
                            >
                              <div className="alert-icon">
                                <span className="svg-icon svg-icon-primary svg-icon-xl" />
                              </div>
                              <div className="alert-text">
                                The form is for users to create or update information related to account password.
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              New Password
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              id="password"
                              type="password"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Password"
                            />
                            {errors.password &&
                              touched.password &&
                              <span className="form-text text-danger">
                                {errors.password}
                              </span>}
                          </div>
                          <div className="form-group">
                            <label>
                              Confirm Password
                              <span className="text-danger"> *</span>
                            </label>
                            <input
                              id="c_password"
                              type="password"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Confirm password"
                            />
                            {errors.c_password &&
                              touched.c_password &&
                              <span className="form-text text-danger">
                                {errors.c_password}
                              </span>}
                          </div>
                          <div className="example-code mt-10">
                            <div className="example-highlight" />
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn btn-primary mr-2"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() =>
                              this.setState ({
                                showChangingPasswordForm: false,
                              })}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const actionCreators = {
  fetchUser,
  updateUser,
  updatePassword,
};

export default connect (mapStateToProps, actionCreators) (Account);
