import server from '../apis/server';
import { serialize } from 'object-to-formdata';
import snakeCaseKeys from 'snakecase-keys';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import serverNoAuth from '../apis/serverNoAuth';

export const actionTypes = {
  FETCH_REPORTS: 'FETCH_REPORTS',
  FETCH_REPORT: 'FETCH_REPORT',
}

export const fetchReports = () => async (dispatch, getState) => {
  const { companyStringId } = getState().auth.user;
  await server.get(`/report/list/${companyStringId}`)
    .then(({ data }) => {
      const reportsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_REPORTS, payload: reportsResult })
    })
};

export const fetchReport = ({ analyticsStringId }) => async (dispatch) => {
  await server.get(`/report/item/${analyticsStringId}`)
    .then(({ data }) => {
      const reportResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_REPORT, payload: reportResult })
    })
};

export const fetchReportByGuest = ({ analyticsStringId }) => async (dispatch) => {
  await serverNoAuth.get(`/report/email/${analyticsStringId}`)
    .then(({ data }) => {
      const reportResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_REPORT, payload: reportResult })
    })
};

export const fetchRecentReports = ({ limit }) => async (dispatch, getState) => {
  const { companyStringId } = getState().auth.user;
  await server.get(`/report/recent/${companyStringId}/${limit}`)
    .then(({ data }) => {
      const reportsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_REPORTS, payload: reportsResult })
    })
};

export const createReport = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.post('/report/create', serialize(formattedData));
};

export const updateReport = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.patch('/report/update', serialize(formattedData));
};

export const deleteReport = ({ analyticsStringId }) => async () => {
  return await server.delete(`/report/item/${analyticsStringId}`);
}

export const sendReport = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.post('/report/email', serialize(formattedData));
};

export const createTempReport = (analyticStringID) => async () => {
  return await server.get(`/report/email/token/${analyticStringID}`);
}