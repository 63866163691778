import server from '../apis/server';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const actionTypes = {
  FETCH_CAMPAIGN_ANALYSIS: 'FETCH_CAMPAIGN_ANALYSIS',
  FETCH_PROJECT_ANALYSIS: 'FETCH_PROJECT_ANALYSIS',
}

export const fetchCampaignAnalysis = ({ campaignStringId }) => async (dispatch) => {
  await server.get(`/report/campaign/analysis/${campaignStringId}`)
    .then(({ data }) => {
      const campaignAnalysisResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CAMPAIGN_ANALYSIS, payload: campaignAnalysisResult })
    })
};

export const fetchProjectAnalysis = () => async (dispatch, getState) => {
  const { projectStringId } = getState().auth.user;
  await server.get(`/report/project/analysis/${projectStringId}`)
    .then(async ({ data }) => {
      let projectAnalysisResult = camelcaseKeysDeep(data.data);
      let { clippingsCountRate } = projectAnalysisResult;
      projectAnalysisResult = { ...projectAnalysisResult, clippingsCountRate: clippingsCountRate }
      dispatch({ type: actionTypes.FETCH_PROJECT_ANALYSIS, payload: projectAnalysisResult })
    })
};