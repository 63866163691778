import React from 'react';
import {connect} from 'react-redux';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import {createTempReport} from '../../redux/actions/reports';
import {successToast, logMsg, errorToast} from '../../helper';
import moment from 'moment';

class CopySummaryReport extends React.Component {
  createTempReport = async () => {
    const analyticsStringId = this.props.analyticsStringId;

    try {
      const response = await this.props.createTempReport (analyticsStringId);
      const {data} = camelcaseKeysDeep (response.data);
      navigator.clipboard.writeText (
        `${window.location.origin}/report/email/${data.token}`
      );
      successToast (
        `Copy this report url to your clipboard. This url will be expired at ${moment (data.expireDatetime).format ('LLL')}`
      );
    } catch (error) {
      const appErrorCode = logMsg (error);
      errorToast ('Something went wrong. Trace ID: ' + appErrorCode);
    }
  };

  render () {
    return (
      <span
        className="btn btn-clean btn-sm btn-icon mr-2"
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="Reply"
        onClick={() => this.createTempReport ()}
      >
        <i className="flaticon2-copy icon-1x" />
      </span>
    );
  }
}

const actionCreators = {
  createTempReport,
};

export default connect (null, actionCreators) (CopySummaryReport);
