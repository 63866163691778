import server from '../apis/server';
import {serialize} from 'object-to-formdata';
import snakeCaseKeys from 'snakecase-keys';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const actionTypes = {
  FETCH_MEETINGS: 'FETCH_MEETINGS',
  FETCH_MEETING: 'FETCH_MEETING',
};

export const fetchMeetings = () => async (dispatch, getState) => {
  const {companyStringId} = getState ().auth.user;
  await server
    .get (`/meeting-report/list/${companyStringId}`)
    .then (({data}) => {
      const meetingsResult = camelcaseKeysDeep (data.data);
      dispatch ({type: actionTypes.FETCH_MEETINGS, payload: meetingsResult});
    });
};

export const fetchMeeting = ({meetingStringId}) => async dispatch => {
  await server
    .get (`/meeting-report/item/${meetingStringId}`)
    .then (({data}) => {
      const meetingResult = camelcaseKeysDeep (data.data);
      dispatch ({type: actionTypes.FETCH_MEETING, payload: meetingResult});
    });
};

export const fetchRecentMeetings = ({limit}) => async (dispatch, getState) => {
  const {companyStringId} = getState ().auth.user;
  await server
    .get (`/meeting-report/recent/${companyStringId}/${limit}`)
    .then (({data}) => {
      const meetingsResult = camelcaseKeysDeep (data.data);
      dispatch ({type: actionTypes.FETCH_MEETINGS, payload: meetingsResult});
    });
};

export const createComment = ({meetingStringId, comment}) => async () => {
  const formattedData = snakeCaseKeys ({meetingStringId, comment});
  return await server.post (
    `/meeting-report/create/${meetingStringId}/comment`,
    serialize (formattedData)
  );
};

export const deleteComment = ({commentStringId}) => async () => {
  return await server.delete (`/meeting-report/comment/${commentStringId}`);
};

export const createMeeting = params => async () => {
  const formattedData = snakeCaseKeys (params);
  return await server.post (
    '/meeting-report/create',
    serialize (formattedData)
  );
};

export const updateMeeting = params => async () => {
  const formattedData = snakeCaseKeys (params);
  return await server.patch (
    '/meeting-report/update',
    serialize (formattedData)
  );
};

export const deleteMeeting = ({meetingStringId}) => async () => {
  return await server.delete (`/meeting-report/item/${meetingStringId}`);
};
