import server from '../apis/server';
import { serialize } from 'object-to-formdata';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import snakeCaseKeys from 'snakecase-keys';

export const actionTypes = {
  FETCH_CAMPAIGNS: 'FETCH_CAMPAIGNS',
  FETCH_CAMPAIGN: 'FETCH_CAMPAIGN',
}

export const fetchCampaigns = () => async (dispatch, getState) => {
  const { projectStringId } = getState().auth.user;
  await server.get(`/campaign/list/${projectStringId}`)
    .then(({ data }) => {
      const campaignsResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CAMPAIGNS, payload: campaignsResult })
    }) 
};

export const fetchCampaign = ({ campaignStringId }) => async (dispatch) => {
  await server.get(`/campaign/item/${campaignStringId}`)
    .then(({ data }) => {
      const campaignResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_CAMPAIGN, payload: campaignResult })
    })
};

export const createCampaign = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.post('/campaign/create', serialize(formattedData));
};

export const updateCampaign = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.patch('/campaign/update', serialize(formattedData));
};

export const deleteCampaign = ({ campaignStringId }) => async () => {
  return await server.delete(`/campaign/item/${campaignStringId}`);
};