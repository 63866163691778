import server from '../apis/server';
import { serialize } from 'object-to-formdata';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import snakeCaseKeys from 'snakecase-keys';

export const actionTypes = {
  FETCH_USER: 'FETCH_USER',
}

export const fetchUser = () => async (dispatch) => {
  await server.get(`/user/info`)
    .then(({ data }) => {
      const userResult = camelcaseKeysDeep(data.data);
      userResult.userStringId = userResult.userId;
      dispatch({ type: actionTypes.FETCH_USER, payload: userResult })
    })
};

export const createUser = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.post('/user/create', serialize(formattedData));
};

export const updateUser = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.patch('/user/update', serialize(formattedData));
};

export const updatePassword = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.put('/user/password', serialize(formattedData));
};

export const deleteUser = ({ userStringId }) => async () => {
  return await server.delete(`/user/item/${userStringId}`);
};