import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import clippingsReducer from "./reducers/clippingsReducer";
import clippingReducer from "./reducers/clippingReducer";
import campaignsReducer from "./reducers/campaignsReducer";
import campaignReducer from "./reducers/campaignReducer";
import channelsReducer from "./reducers/channelsReducer";
import uomListReducer from './reducers/uomListReducer';
import channelTypesReducer from "./reducers/channelTypesReducer";
import channelReducer from "./reducers/channelReducer";
import projectsReducer from "./reducers/projectsReducer";
import meetingsReducer from "./reducers/meetingsReducer";
import meetingReducer from "./reducers/meetingReducer";
import userReducer from "./reducers/userReducer";
import plansReducer from "./reducers/plansReducer";
import planReducer from "./reducers/planReducer";
import companiesReducer from "./reducers/companiesReducer";
import companyReducer from "./reducers/companyReducer";
import companyUsersReducer from "./reducers/companyUsersReducer";
import companyUserReducer from "./reducers/companyUserReducer";
import reportsReducer from "./reducers/reportsReducer";
import reportReducer from "./reducers/reportReducer";
import campaignsClippingsReducer from "./reducers/campaignsClippingsReducer";
import campaignAnalysisReducer from "./reducers/campaignAnalysisReducer";
import projectAnalysisReducer from "./reducers/projectAnalysisReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  clippings: clippingsReducer,
  clipping: clippingReducer,
  campaigns: campaignsReducer,
  campaign: campaignReducer,
  channels: channelsReducer,
  uomList: uomListReducer,
  channelTypes: channelTypesReducer,
  channel: channelReducer,
  projects: projectsReducer,
  meetings: meetingsReducer,
  meeting: meetingReducer,
  plans: plansReducer,
  plan: planReducer,
  user: userReducer,
  companies: companiesReducer,
  company: companyReducer,
  companyUsers: companyUsersReducer,
  companyUser: companyUserReducer,
  reports: reportsReducer,
  report: reportReducer,
  campaignsClippings: campaignsClippingsReducer,
  campaignAnalysis: campaignAnalysisReducer,
  projectAnalysis: projectAnalysisReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
