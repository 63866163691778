import React from 'react';
import MessageCard from '../../components/MessageCard';

class SocialListening extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="card card-custom card-stretch gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Social Listening List</h3>
                  </div>
                </div>
                <div className="card-body">
                  <MessageCard color="success" message="Your package is not applicable to this feature. Please contact MODERNTEER to upgrade your package." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialListening;