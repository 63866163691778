import React from 'react';
import Chart from 'react-apexcharts';
import { numberWithCommas } from '../../../../helper';

const LineChart = ({ data }) => {
  data = data.filter(({ sumPr }) => sumPr > 0); 
  data = data.sort((a,b) => (new Date(b.date) - new Date(a.date)));

  const _series = [{ name: 'Overall', data: data.map(({ date, sumPr }) => ({ x: new Date(date), y: sumPr })) }]; 

  const options = {
    stroke: {
      width: 1.5,
    },
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    colors: ['#06B9BC'],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        if (val > 0) {
          return numberWithCommas(val);
        }
      },
    },
    markers: {
      size: 0,
    },
    title: {
      text: 'Monthly Pr Value',
      align: 'left'
    },
    fill: {
      colors: ['#06B9BC'],
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return numberWithCommas(val);
        },
      },
      title: {
        text: 'PR Value'
      },
    },
    xaxis: {
      type: 'datetime'
    },
  };

  
  return (
    <Chart options={options} series={_series} type="area" height={350} />
  );
}

export default LineChart;
