import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {fetchRecentReports} from '../../../redux/actions/reports';
import {fetchCampaigns} from '../../../redux/actions/campaigns';
import {fetchCampaignAnalysis} from '../../../redux/actions/analytics';
import {getSelectList, logMsg, errorToast} from '../../../helper';
import LineChart from './components/LineChart';
import ReportList from './components/ReportList';
import SingleSelect from '../../components/SingleSelect';
import Loader from '../../components/Loader';

class NewsReportsAlalytics extends React.Component {
  state = {
    isLoading: true,
    selectedCampaignId: null,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchCampaigns ();
      // set default selected campaign
      if (this.props.campaigns && this.props.campaigns.length > 0) {
        await this.setState ({
          selectedCampaignId: this.props.campaigns[0].campaignId,
        });
      }
      // get campaignAnalysis by selected campaign
      await this.getCampaignAnalysis ();
      // get recent 10 summary reports
      await this.props.fetchRecentReports ({limit: 10});
    } catch (error) {
      const appErrorCode = logMsg (error);
      errorToast ('Something went wrong. Trace ID: ' + appErrorCode);
    } finally {
      this.setState ({isLoading: false});
    }
  };

  getCampaignAnalysis = async () => {
    if (this.state.selectedCampaignId) {
      await this.props.fetchCampaignAnalysis ({
        campaignStringId: this.props.campaigns.find (
          ({campaignId}) => campaignId === this.state.selectedCampaignId
        ).campaignStringId,
      });
    }
    this.setState({ isLoading: false });
  };
  
  render () {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const {selectedCampaignId} = this.state;
    const {channelsProportionRate, graphData} = this.props.campaignAnalysis;

    return (
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="column">
                <div className="card card-custom gutter-b">
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label">Campaign Analysis</h3>
                    </div>
                    <div className="card-toolbar">
                      <SingleSelect
                        valueName="channelTypeCode"
                        value={selectedCampaignId}
                        onChange={async (name, value) => {
                          await this.setState({ isLoading: true });
                          await this.setState ({selectedCampaignId: value});
                          await this.getCampaignAnalysis ();
                        }}
                        onBlur={() => null}
                        options={getSelectList (
                          this.props.campaigns,
                          'campaignId',
                          'campaignName'
                        )}
                        width="200px"
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    {graphData && graphData.length > 0 ?
                      <LineChart data={graphData} /> :
                      null
                    }
                  </div>
                </div>
                {channelsProportionRate && channelsProportionRate.length
                  ? <div className="card card-custom card-stretch gutter-b">
                      <div className="card-body">
                        <div className="d-flex align-items-center flex-wrap mt-8">
                          {channelsProportionRate.map ((item, key) => {
                            const {
                              channelTypeCode,
                              channelTypeName,
                              prRate,
                            } = item;
                            return (
                              <div
                                className="d-flex align-items-center flex-lg-fill mr-5 mb-5"
                                key={key}
                              >
                                <span className="mr-6">
                                  {/* <i className="flaticon2-speaker display-4 text-muted font-weight-bold" /> */}
                                  <img
                                    src={`/images/${channelTypeCode}.png`}
                                    alt=""
                                    className="symbol"
                                    style={{width: '50px', height: '50px'}}
                                  />
                                </span>
                                <div className="d-flex flex-column text-dark-75">
                                  <span className="font-weight-bolder font-size-lg">
                                    {channelTypeName}
                                  </span>
                                  <span className="font-weight-bolder font-size-h2">
                                    <span className="text-dark-50 font-weight-bold" />
                                    {Math.round (prRate, 2)}%
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  : null}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card card-custom card-stretch gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">
                      Daily News Reports & Analytics
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <NavLink to="/summary-reports">
                      <span className="btn btn-light btn-sm">More</span>
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <ReportList reports={this.props.reports} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reports: state.reports,
    campaigns: state.campaigns,
    campaignAnalysis: state.campaignAnalysis,
  };
};

const actionCreators = {
  fetchRecentReports,
  fetchCampaigns,
  fetchCampaignAnalysis,
};

export default connect (mapStateToProps, actionCreators) (NewsReportsAlalytics);
