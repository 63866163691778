import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  updateCompanyInfo: "[UpdateCompanyInfo] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { user } = action.payload;
        return { user: user };
      }
      case actionTypes.updateCompanyInfo: {
        const { companyName, companyImage } = action.payload;
        return { ...state, user: { ...state.user, companyName: companyName, companyImage: companyImage  }};
      }
      case actionTypes.Logout: {
        return initialAuthState;
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: (user) => {
    return { type: actionTypes.Login, payload: { user } };
  },
  logout: () => ({ type: actionTypes.Logout }),
  updateCompanyInfo: (companyInfo) => {
    return { type: actionTypes.updateCompanyInfo, payload: companyInfo };
  },
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();
  //   yield put(actions.fulfillUser(user));
  // });
}
