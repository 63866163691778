import React from 'react';
import { getSentiment } from '../../helper';

const SentimentCodeLabel = ({sentimentCode}) => {
  let style = {};
  switch (sentimentCode) {
    case 'G':
      style.color = 'sentiment-positive-bg';
      break;
    case 'N':
      style.color = 'sentiment-normal-bg';
      break;
    case 'B':
      style.color = 'sentiment-negative-bg';
      break;
    default:
      style.color = 'sentiment-positive-bg';
      break;
  }
  return (
    <div className="text-center">
      <span
        className={`label label-lg label-inline ${style.color} font-weight-bold py-4`}
      >
        {getSentiment (sentimentCode)}
      </span>
    </div>
  );
};

export default SentimentCodeLabel;
