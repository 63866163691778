import React from 'react';

const MessageCard = ({ message, color }) => {
  return (
    <div className={`alert alert-custom alert-light-${color} fade show mb-5`} role="alert">
      <div className="alert-icon">
        <i className={`flaticon-info`}></i>
      </div>
      <div className="alert-text">{message}</div>
    </div>
  );
};

export default MessageCard;