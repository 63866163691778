import React, { lazy, Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import NewsReportsAnalytics from "./pages/NewsReportsAlalytics";
import MainPage from "./pages/MainPage";
import SocialListening from "./pages/SocialListening";
import Account from "./pages/Account";
import { useSelector } from "react-redux";

const SummaryReportsPage = lazy(() =>
  import("./modules/SummaryReports/SummaryReportsPage")
);

const ClippingsPage = lazy(() =>
  import("./modules/Clippings/ClippingsPage")
);

const MeetingsPage = lazy(() =>
  import("./modules/Meetings/MeetingsPage")
);

const PlansPage = lazy(() =>
  import("./modules/Plans/PlansPage")
);

const CampaignsPage = lazy(() =>
  import("./modules/Campaigns/CampaignsPage")
);

const ChannelsPage = lazy(() =>
  import("./modules/Channels/ChannelsPage")
);

const CompaniesPage = lazy(() =>
  import("./modules/Companies/CompaniesPage")
);

export default function BasePage() {
  const { userRoleCode } = useSelector((state) => state.auth.user);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/main-page" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        {/* pages */}
        <ContentRoute path="/main-page" component={MainPage} />
        <ContentRoute path="/news-reports-analytics" component={NewsReportsAnalytics} />
        <Route path="/summary-reports" component={SummaryReportsPage} />
        <Route path="/clippings" component={ClippingsPage} />
        <Route path="/meetings" component={MeetingsPage} />
        <ContentRoute path="/social-listening" component={SocialListening} />
        <Route path="/plans" component={PlansPage} />
        <ContentRoute path="/account" component={Account} />
        {userRoleCode === 'ADM' ?
          <React.Fragment>
            <Route path="/campaigns" component={CampaignsPage} />
            <Route path="/channels" component={ChannelsPage} />
            <Route path="/companies" component={CompaniesPage} />
          </React.Fragment> :
          null
        }
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
