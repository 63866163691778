import server from '../apis/server';
import { serialize } from 'object-to-formdata';
import snakeCaseKeys from 'snakecase-keys';
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const actionTypes = {
  FETCH_COMPANIES: 'FETCH_COMPANIES',
  FETCH_COMPANY: 'FETCH_COMPANY',
  FETCH_COMPANY_USERS: 'FETCH_COMPANY_USERS',
  FETCH_COMPANY_USER: 'FETCH_COMPANY_USER',
}

export const getCompanies = async () => {
  let companiesResult = [];
  await server.get(`/company/list`)
    .then(({ data }) => {
      companiesResult = camelcaseKeysDeep(data.data);
    })
  return companiesResult;
};

export const fetchCompanies = () => async (dispatch) => {
  await server.get(`/company/list`)
    .then(({ data }) => {
      const companiesResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_COMPANIES, payload: companiesResult })
    })
};

export const fetchCompany = ({ companyStringId }) => async (dispatch) => {
  await server.get(`/company/item/${companyStringId}`)
    .then(({ data }) => {
      const companyResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_COMPANY, payload: companyResult })
    })
};

export const createCompany = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.post('/company/create', serialize(formattedData));
};

export const updateCompany = (params) => async () => {
  const formattedData = snakeCaseKeys(params);
  return await server.patch('/company/update', serialize(formattedData));
};

export const deleteCompany = ({ companyStringId }) => async () => {
  return await server.delete(`/company/item/${companyStringId}`);
};

export const fetchCompanyUsers = ({ companyStringId }) => async (dispatch) => {
  await server.get(`/company/user/${companyStringId}`)
    .then(({ data }) => {
      const companyUsersResult = camelcaseKeysDeep(data.data);
      dispatch({ type: actionTypes.FETCH_COMPANY_USERS, payload: companyUsersResult })
    })
};

export const fetchCompanyUser = ({ userStringId }) => async (dispatch) => {
  await server.get(`/user/item/${userStringId}`)
    .then(({ data }) => {
      const userResult = camelcaseKeysDeep(data.data);
      userResult.userStringId = userResult.userId;
      dispatch({ type: actionTypes.FETCH_COMPANY_USER, payload: userResult })
    })
};
