import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { NavLink } from 'react-router-dom';
import SentimentLabel from '../../../components/SentimentLabel';
import moment from 'moment';

const RportList = ({ reports }) => {
  const columns = [
    {
      dataField: 'analyticsDate',
      text: 'Date',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{moment(row.analyticsDate).format('DD-MMM-YYYY')}</span>
      }
    },
    {
      dataField: 'clipping_topic',
      text: 'Topic',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <NavLink to={`/summary-reports/preview/${row.analyticsStringId}`}><span>{row.analyticsTopic}</span></NavLink>
      }
    },
    {
      dataField: 'clipping_sentiment_code',
      text: 'Sentiment',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <SentimentLabel sentimentName={row.sentimentName} />
      }
    },
  ];

  return (
    <BootstrapTable
      bordered={false}
      keyField="analyticsId"
      data={reports}
      columns={columns}
    />
  );
}

export default RportList;