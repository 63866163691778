import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import PDFComponent from './PDFComponent';
import {
  successToast,
  getSentimentColor,
  getSentiment,
  numberWithCommas,
} from '../../helper';
import {isMobile} from 'react-device-detect';
import moment from 'moment';

const ClippingPreview = ({userRoleCode, clipping}) => {
  const [showInfo, setShowInfo] = useState (!isMobile);

  const {
    campaignName,
    channelName,
    channelTypeName,
    clippingAdValue,
    clippingDesc,
    clippingImages,
    clippingPrValue,
    clippingSentimentCode,
    clippingStringId,
    clippingTopic,
    clippingUploadDate,
  } = clipping;

  const copyUrlToClipboard = () => {
    successToast ('Copy this clipping url to your clipboard.');
  };

  const clippingPreviewUrl = `${window.location.origin}/clippings/preview/${clippingStringId}`;

  return (
    <div className="card card-custom gutter-b example example-compact">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex align-items-center mr-2 py-2">
            <div className="font-weight-bold font-size-h3 mr-3">
              {clippingTopic}
            </div>
          </div>
        </div>

        <div className="d-flex py-6 flex-column flex-md-row flex-lg-column flex-xxl-row justify-content-between">
          <div className="d-flex align-items-center">
            <span className="font-weight-bolder font-size-h6">
              <i className="flaticon2-layers-2 font-weight-bold mr-2" />
              Sentiment:
              {' '}
              <span
                className={`font-weight-bolder ${getSentimentColor (clippingSentimentCode)}`}
              >
                {getSentiment (clippingSentimentCode)}
              </span>
            </span>
          </div>
          <div className="d-flex my-2 my-xxl-0 align-items-md-center align-items-lg-start align-items-xxl-center flex-column flex-md-row flex-lg-column flex-xxl-row">
            <div className="font-weight-bold text-muted mx-2">
              {moment (clippingUploadDate).format ('LL')}
            </div>
            <div
              className="d-flex align-items-center flex-wrap flex-xxl-nowrap"
              data-inbox="toolbar"
            >
              <CopyToClipboard
                text={clippingPreviewUrl}
                onCopy={copyUrlToClipboard}
              >
                <span
                  className="btn btn-default btn-sm btn-icon mr-2"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Reply"
                >
                  <i className="flaticon2-copy icon-1x" />
                </span>
              </CopyToClipboard>
              {userRoleCode !== "CLI"
                ? <NavLink
                    to={`/clippings/edit/${clippingStringId}`}
                    className="btn btn-default btn-sm btn-icon"
                    data-dismiss="modal"
                  >
                    <i className="flaticon2-writing" />
                  </NavLink>
                : null}
            </div>

          </div>
        </div>
        <div
          className="d-flex align-items-center flex-wrap card-body bg-light pt-2 pb-0"
          style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}
        >
          <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <div className="d-flex flex-column text-dark-75">
              <span className="font-weight-bolder font-size-h5">
                Campaign:
                {' '}
                <span className="font-weight-normal">{campaignName}</span>
              </span>
            </div>
          </div>
          {isMobile &&
            <button
              className="btn btn-transparent pl-0 pr-0 pt-2 text-primary text-right"
              onClick={() => setShowInfo (!showInfo)}
            >
              {showInfo ? 'Hide' : 'Show'} info
            </button>}
        </div>
        {showInfo &&
          <React.Fragment>
            <div className="d-flex align-items-center flex-wrap card-body bg-light pb-2 pt-2">
              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    <i className="flaticon2-cube-1 font-weight-bold mr-2" />
                    Type:
                    {' '}
                    <span className="text-success font-weight-normal">
                      {channelTypeName}
                    </span>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    <i className="flaticon2-paper font-weight-bold mr-2" />
                    Media:
                    {' '}
                    <span className="text-success font-weight-normal">
                      {channelName}
                    </span>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    <i className="flaticon2-graph-1 font-weight-bold mr-2" />
                    AD Value:
                    {' '}
                    <span className="text-success font-weight-normal">
                      ฿ {numberWithCommas (clippingAdValue)}
                    </span>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    <i className="flaticon2-graphic font-weight-bold mr-2" />
                    PR Value:
                    {' '}
                    <span className="text-success font-weight-normal">
                      ฿ {numberWithCommas (clippingPrValue)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/* <div
              className="d-flex align-items-center flex-wrap card-body bg-light pt-2"
              style={{
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
            >
              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-h6">
                    <i className="flaticon2-layers-2 font-weight-bold mr-2" />
                    Sentiment:
                    {' '}
                    <span
                      className={`font-weight-bolder ${getSentimentColor (clippingSentimentCode)}`}
                    >
                      {getSentiment (clippingSentimentCode)}
                    </span>
                  </span>
                </div>
              </div>
            </div> */}
          </React.Fragment>}
        <div className="pb-5" />
        <div className="card card-custom card-shadowless">
          {clippingImages && clippingImages.length > 0
            ? <PDFComponent file={clippingImages[0].imageUrl} />
            : null}
        </div>
        <div className="pb-10" />
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    Clipping URL
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control disabled"
                  onChange={() => null}
                  value={clippingPreviewUrl}
                  placeholder="0.00"
                  aria-label="Amount (to the nearest dollar)"
                />
                <CopyToClipboard
                  text={clippingPreviewUrl}
                  onCopy={copyUrlToClipboard}
                >
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                      <i className="flaticon2-copy icon-1x" />
                    </button>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="py-3 toggle-off-item">
              <div dangerouslySetInnerHTML={{__html: clippingDesc}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClippingPreview;
