import axios from 'axios';
import server from '../../../../redux/apis/server';
import firebase from "firebase/app";
import camelcaseKeysDeep from 'camelcase-keys-deep';

export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const login = async (email, password) => {
  await firebase.auth().signInWithEmailAndPassword(email, password);
  const user = await server.get('/user/info');
  const userResult = camelcaseKeysDeep(user.data.data);
  const projects = await server.get(`/project/list/${userResult.companyStringId}`);
  const projectStringId = projects.data.data && projects.data.data.length > 0 ? camelcaseKeysDeep(projects.data.data[0]).projectStringId : null;
  userResult.projectStringId = projectStringId;
  if (!userResult || !projectStringId) {
    Promise.reject();
    return;
  }
  return { user: userResult };
}

export const logout = async () => {
  await firebase.auth().signOut();
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}