import axios from 'axios';
import firebase from "firebase/app";
import { actionTypes } from '../../app/modules/Auth/_redux/authRedux'
import { logMsg } from '../../helper';
import store from '../store';
import { logout } from '../../app/modules/Auth/_redux/authCrud';

const getAuthToken = async () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(response => {
      if (response) {
        response.getIdToken(true).then((idToken) => resolve(idToken));
      } else {
        logout().then(() => store.dispatch({ type: actionTypes.Logout }));
      }
    })
  })
}

const server = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

server.interceptors.request.use(async (config) => {
  const authToken = await getAuthToken();
  config.headers['Authorization'] = 'Bearer ' + authToken;
  return config;
});

server.interceptors.response.use(response => response
  , error => {
    error.appErrorCode = logMsg(error);
    return Promise.reject(error);
  });

export default server;
