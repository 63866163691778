import React from 'react';
import Select from 'react-select';

class SingleSelect extends React.Component {
  handleChange = selected => {
    this.props.onChange (this.props.valueName, selected.value);
  };

  handleBlur = () => {
    this.props.onBlur (this.props.valueName, true);
  };

  render () {
    const selected = this.props.options.find(({value}) => value === this.props.value);

    return (
      <Select
        id={this.props.valueName}
        options={this.props.options}
        multi={true}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={selected ? selected : null}
        styles={{
          menu: provided => ({ ...provided, zIndex: 99 }),
          control: base => ({ ...base, borderColor: '#EBEDF3', borderRadius: '4px', width: this.props.width ? this.props.width : base.width })
        }}
      />
    );
  }
}

export default SingleSelect;
