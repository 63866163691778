import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {
  logMsg,
  errorToast,
  numberWithCommas,
  limitString,
} from '../../../helper';
import {fetchProjectAnalysis, fetchCampaignAnalysis} from '../../../redux/actions/analytics';
import {fetchRecentReports} from '../../../redux/actions/reports';
import {fetchRecentClippings} from '../../../redux/actions/clippings';
import {fetchRecentPlans} from '../../../redux/actions/plans';
import {fetchRecentMeetings} from '../../../redux/actions/meetings';
import SentimentLabel from '../../components/SentimentLabel';
import LineChart from './components/LineChart';
import ClippingList from './components/ClippingList';
import Loader from '../../components/Loader';

class MainPage extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchProjectAnalysis ();
      await this.props.fetchRecentClippings ({limit: 14});
      await this.props.fetchRecentReports ({limit: 5});
      await this.props.fetchRecentMeetings ({limit: 5});
      await this.props.fetchRecentPlans ({limit: 1});
      // await this.props.fetchCampaignAnalysis ({
      //   campaignStringId: '1eabf779-9bc6-4817-abe2-f95b13ae3032',
      // });
      this.setState ({isLoading: false});
    } catch (error) {
      const appErrorCode = logMsg (error);
      errorToast ('Something went wrong. Trace ID: ' + appErrorCode);
    } finally {
    }
  };

  render () {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const {graphData, channelsProportionRate} = this.props.projectAnalysis;
    const {adValue, prValue} = this.props.projectAnalysis.projectReportValue;
    // const {channelsProportionRate} = this.props.campaignAnalysis

    return (
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="card card-custom card-stretch gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Overall Analytics</h3>
                  </div>
                </div>
                <div className="card-body">
                  <LineChart data={graphData} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              {/* <div className="card card-custom card-stretch gutter-b"> */}
              <div className="card card-custom gutter-b mb-5">
                <div
                  className="card-header justify-content-center align-content-center p-5"
                  style={{width: '100%'}}
                >
                  <div className="d-flex flex-column text-dark-75">
                    <span className="font-weight-bolder font-size-lg custom-primary">
                      <img
                        src={`/images/PRVALUE.png`}
                        alt=""
                        className="symbol mr-2"
                        width={25}
                      />
                      PR Value (฿)
                    </span>
                    <span className="font-size-h1 text-center custom-secondary">
                      <span className="text-dark-50 font-weight-bold" />
                      {numberWithCommas (prValue)}
                    </span>
                    <span>AD Value (฿): {numberWithCommas (adValue)}</span>
                  </div>
                </div>
              </div>
              {channelsProportionRate &&
                channelsProportionRate.length > 0 &&
                <div className="row">
                  {channelsProportionRate.map ((item, key) => (
                    <div key={key} className="col-md-6 mb-5">
                      <div
                        className="bg-white align-items-center text-center rounded p-5"
                        style={{width: '100%'}}
                      >
                        <span className="font-size-h6 font-weight-bolder custom-primary mb-2">
                          {item.channelTypeName}
                        </span>
                        <div className="d-flex align-items-center justify-content-center mr-2">
                          <div className="symbol symbol-60 symbol-light-info mr-4 flex-shrink-0">
                            <img
                              src={`/images/${item.channelTypeCode}.png`}
                              alt=""
                            />
                          </div>
                          <div style={{borderBottom: '5px solid #FFAC00'}}>
                            <div className="font-size-h1 font-weight-bolder">
                              {Math.round (item.prRate)}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>}
            </div>
            <div className="col-lg-8">
              <div className="card card-custom card-stretch gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">News Clipping</h3>
                  </div>
                  <div className="card-toolbar">
                    <NavLink to="/clippings">
                      <span className="btn btn-light btn-sm">More</span>
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <ClippingList clippings={this.props.clippings} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              {this.props.reports &&
                this.props.reports.length > 0 &&
                <div className="card card-custom gutter-b">
                  <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">
                      Summary & Analytics
                    </h3>
                    <div className="card-toolbar" />
                  </div>
                  <div className="card-body pt-0">
                    {this.props.reports.map ((report, key) => {
                      return (
                        <div
                          key={key}
                          className={`d-flex align-items-center ${key % 2 === 0 ? 'alert-secondary' : ''} p-5`}
                        >
                          <span className="svg-icon svg-icon-warning mr-5">
                            <span className="svg-icon svg-icon-lg">
                              <SentimentLabel
                                sentimentName={report.sentimentName}
                              />
                            </span>
                          </span>
                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`summary-reports/preview/${report.analyticsStringId}`}
                              className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >
                              {limitString (report.analyticsTopic, 25)}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>}
              {this.props.meetings &&
                this.props.meetings.length > 0 &&
                <div className="card card-custom gutter-b">
                  <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">
                      Meetings
                    </h3>
                    <div className="card-toolbar" />
                  </div>
                  <div className="card-body pt-0">
                    {this.props.meetings.map ((meeting, key) => {
                      return (
                        <div
                          key={key}
                          className={`d-flex align-items-center ${key % 2 === 0 ? 'alert-secondary' : ''} p-5`}
                        >
                          <span className="svg-icon svg-icon-warning mr-5">
                            <span className="svg-icon svg-icon-lg">
                              <img src={`/images/ARTBOARD.png`} alt="" width={25} />
                            </span>
                          </span>
                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`meetings/preview/${meeting.meetingStringId}`}
                              className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                            >
                              {limitString (meeting.meetingTopic, 25)}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    reports: state.reports,
    plans: state.plans,
    meetings: state.meetings,
    clippings: state.clippings,
    projectAnalysis: state.projectAnalysis,
    campaignAnalysis: state.campaignAnalysis,
  };
};

const actionCreators = {
  fetchCampaignAnalysis,
  fetchRecentReports,
  fetchRecentPlans,
  fetchRecentMeetings,
  fetchRecentClippings,
  fetchProjectAnalysis,
};

export default connect (mapStateToProps, actionCreators) (MainPage);
