import React from 'react';
import {NavLink} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import SentimentCodeLabel from '../../../components/SentimentCodeLabel';
import moment from 'moment';
import {numberWithCommas} from '../../../../helper';

const ClippingList = ({clippings}) => {
  const columns = [
    {
      dataField: 'clippingTopic',
      text: 'Topic',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <NavLink to={`/clippings/preview/${row.clippingStringId}`}>
            <img src="/images/MEN.png" className="mr-2" width={15} alt="" />
            {row.clippingTopic}
          </NavLink>
        );
      },
    },
    {
      dataField: 'campaignName',
      text: 'Campaign',
    },
    {
      dataField: 'channelName',
      text: 'Channel',
      headerStyle: {textAlign: 'center'},
      style: {textAlign: 'center'},
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span className="custom-primary">{row.channelName}</span>;
      },
    },
    {
      dataField: 'clippingUploadDate',
      text: 'Date',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{moment (row.clippingUploadDate).format ('DD-MMM-YYYY')}</span>;
      },
    },
    {
      dataField: 'clippingSentimentName',
      text: 'Sentiment',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <SentimentCodeLabel sentimentCode={row.clippingSentimentCode} />;
      },
    },
    {
      dataField: 'clippingPrValue',
      text: 'PR Value (฿)',
      headerStyle: {textAlign: 'center'},
      style: {textAlign: 'center'},
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <span className="text-danger">
            {numberWithCommas (row.clippingPrValue)}
          </span>
        );
      },
    },
  ];

  return (
    <BootstrapTable bordered={false} keyField="clippingId" data={clippings} columns={columns} />
  );
};

export default ClippingList;
