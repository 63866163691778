import { actionTypes } from "../actions/projects";

const variable = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS:
      return action.payload;
    default:
      return state;
  }
};

export default variable;