/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
// import { useLocation } from "react-router";
// import { NavLink } from "react-router-dom";
// import { checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const { user } = useSelector((state) => state.auth);

    // const location = useLocation();
    // const getMenuItemActive = (url) => {
    //     return checkIsActive(location, url) ? "menu-item-active" : "";
    // }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel`}>
                <img className="symbol" src={user.companyImage} alt="" style={{ maxWidth: '40px', maxHeight: '40px' }} />
                <span className="menu-link">
                    <span className="menu-text">{user.companyName}</span>
                </span>
            </li>
        </ul>
        {/*begin::Header Nav*/}
        {/* <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/main-page')}`}>
                <NavLink className="menu-link" to="/main-page">
                    <span className="menu-text">Main Page</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/news-reports-analytics')}`}>
                <NavLink className="menu-link" to="/news-reports-analytics">
                    <span className="menu-text">News Report & Analytics</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/summary-reports')}`}>
                <NavLink className="menu-link" to="/summary-reports">
                    <span className="menu-text">Summary Reports</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/clippings')}`}>
                <NavLink className="menu-link" to="/clippings">
                    <span className="menu-text">Clippings</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/meetings')}`}>
                <NavLink className="menu-link" to="/meetings">
                    <span className="menu-text">Meetings</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/social-listening')}`}>
                <NavLink className="menu-link" to="/social-listening">
                    <span className="menu-text">Social Listening</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/plans')}`}>
                <NavLink className="menu-link" to="/plans">
                    <span className="menu-text">Plans</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/account')}`}>
                <NavLink className="menu-link" to="/account">
                    <span className="menu-text">Account</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
        </ul> */}
        {/*end::Header Nav*/}
    </div>;
}
