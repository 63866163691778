/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/storage';
import SummaryReportPreviewGuest from './pages/SummaryReportPreviewGuest';

export function Routes() {
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  // check if firebase already exists
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } 

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route exact={true} path="/report/email/:id" component={SummaryReportPreviewGuest} />
      {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
          <Redirect from="/auth" to="/" />
        )}
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Layout>
        <BasePage />
      </Layout>
    </Switch>
  );
}
