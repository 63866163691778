import React from 'react';
import Chart from 'react-apexcharts';
import { numberWithCommas } from '../../../../helper';

const LineChart = ({ data }) => {
  // data = data.filter(({ sumPr }) => sumPr > 0);
  data = data.sort((a,b) => (new Date(b.date) - new Date(a.date)));

  const uniqueChannelIds = [
    ...new Set (data.map (item => item.channelTypeCode)),
  ];

  // filter only channel with value
  let prValueChannelIds = [];
  uniqueChannelIds.forEach(channelTypeCode => {
    const channel = data.filter(item => item.channelTypeCode === channelTypeCode);
    channel.forEach(item => {
      if (item.sumPr > 0 && prValueChannelIds.indexOf(item.channelTypeCode) === -1) {
        prValueChannelIds.push(item.channelTypeCode);
      }
    });
  });

  const _series = prValueChannelIds.map(channelTypeCode => {
    const _name = data.find(item => (item.channelTypeCode === channelTypeCode)).channelTypeName;
    const _data = data.filter(item => (item.channelTypeCode === channelTypeCode)).map(item => ({ x: new Date(item.date), y: item.sumPr }));
    return { name: _name, data: _data };
  });

  // console.log(_series);

  const options = {
    stroke: {
      width: 1.5,
    },
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false,
      formatter: function (val, opts) {
        if (val > 0) {
          return numberWithCommas(val);
        }
      },
    },
    markers: {
      size: 0,
    },
    title: {
      text: 'PR Value by Channels by Months',
      align: 'left'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return numberWithCommas(val);
        },
      },
      title: {
        text: 'PR Value'
      },
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      shared: false,
    }
  };

  return (
    <Chart options={options} series={_series} type="area" height={350} />
  );
}

export default LineChart;
