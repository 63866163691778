/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text" style={{color: '#fff'}}>PR - Analytics</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/main-page", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/main-page">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-graphic-1`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>Main Page</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/news-reports-analytics", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/news-reports-analytics">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-speaker`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>News Reports And Analytics</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/summary-reports", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/summary-reports">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-notepad`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>Summary Reports</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/clippings", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/clippings">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-copy`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>Clippings</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/meetings", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/meetings">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-group`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>Meetings</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/social-listening", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/social-listening">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-rocket`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>Social Listening</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/plans", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/plans">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-calendar-5`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>Plans</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/account", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/account">
            <span className="svg-icon menu-icon">
              <i className={`flaticon2-user-1`} style={{color: '#fff'}}></i>
            </span>
            <span className="menu-text" style={{color: '#fff'}}>Account</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {user.userRoleCode === 'ADM' ?
          <React.Fragment>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text" style={{color: '#fff'}}>Company Master Data</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::1 Level*/}
            {/* <li
              className={`menu-item ${getMenuItemActive("/test", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/test">
                <span className="svg-icon menu-icon">
                  <i className={`flaticon-map`} style={{color: '#fff'}}></i>
                </span>
                <span className="menu-text" style={{color: '#fff'}}>License Management</span>
              </NavLink>
            </li> */}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/campaigns", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/campaigns">
                <span className="svg-icon menu-icon">
                  <i className={`flaticon2-console`} style={{color: '#fff'}}></i>
                </span>
                <span className="menu-text" style={{color: '#fff'}}>Campaign Management</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text" style={{color: '#fff'}}>Global Master Data</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/channels", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/channels">
                <span className="svg-icon menu-icon">
                  <i className={`flaticon2-talk`} style={{color: '#fff'}}></i>
                </span>
                <span className="menu-text" style={{color: '#fff'}}>Channel Management</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive("/companies", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/companies">
                <span className="svg-icon menu-icon">
                  <i className={`flaticon2-shelter`} style={{color: '#fff'}}></i>
                </span>
                <span className="menu-text" style={{color: '#fff'}}>Company Management</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </React.Fragment>
          : null
        }

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
