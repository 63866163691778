import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {isMobile} from 'react-device-detect';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DownloadComponent from './FileDownloadComponent';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFComponent = ({file}) => {
  const [numPages, setNumPages] = useState (null);
  const [pageNumber, setPageNumber] = useState (1);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages (numPages);
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          {/* <p>Page {pageNumber} of {numPages}</p> */}
        </div>
        <div className="col-lg-6 text-right">
          <DownloadComponent file={file}>Download</DownloadComponent>
          {/* <button className="btn btn-icon"
            disabled={pageNumber===1}
            onClick={() => setPageNumber(pageNumber-1)}
          >
            <i className="flaticon2-back mr-5" />
          </button>
          <button className="btn btn-icon"
            disabled={pageNumber===numPages}
            onClick={() => setPageNumber(pageNumber+1)}
          >
            <i className="flaticon2-next" />
          </button> */}
        </div>
      </div>
      {!isMobile
        ? <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        : <DownloadComponent file={file}>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </DownloadComponent>}
      <div className="row">
        <div className="col-lg-6">
          <p>Page {pageNumber} of {numPages}</p>
        </div>
        <div className="col-lg-6 text-right">
          {/* <a href={file} rel="noreferrer" target="_blank" className="mr-5" download>Download</a> */}
          <button
            className="btn btn-icon"
            disabled={pageNumber === 1}
            onClick={() => setPageNumber (pageNumber - 1)}
          >
            <i
              className={`flaticon2-back mr-5 icon-2x ${pageNumber !== 1 ? 'custom-primary' : null}`}
            />
          </button>
          <button
            className="btn btn-icon"
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber (pageNumber + 1)}
          >
            <i
              className={`flaticon2-next icon-2x ${pageNumber !== numPages ? 'custom-primary' : null}`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PDFComponent;
