import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as auth from '../_redux/authRedux';
import {login} from '../_redux/authCrud';
import SingleSelect from '../../../components/SingleSelect';
import {getCompanies} from '../../../../redux/actions/companies';
import {errorToast, logMsg, getSelectList} from '../../../../helper';
import {getProjects} from '../../../../redux/actions/projects';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: '',
  password: '',
};

function Form (props) {
  const {intl} = props;
  const [loading, setLoading] = useState (false);
  const [companyList, setCompanyList] = useState ([]);
  const [user, setUser] = useState (null);
  const [selectedCompanyId, setSelectedCompanyId] = useState (null);
  const LoginSchema = Yup.object ().shape ({
    email: Yup.string ()
      .email ('Wrong email format')
      .min (3, 'Minimum 3 symbols')
      .max (50, 'Maximum 50 symbols')
      .required (
        intl.formatMessage ({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string ()
      .min (3, 'Minimum 3 symbols')
      .max (50, 'Maximum 50 symbols')
      .required (
        intl.formatMessage ({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const enableLoading = () => {
    setLoading (true);
  };

  const disableLoading = () => {
    setLoading (false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik ({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      enableLoading ();
      setTimeout (() => {
        login (values.email, values.password)
          .then (userResult => {
            disableLoading ();
            setUser (userResult.user);
            const {userRoleCode} = userResult.user;
            // use default company
            if (userRoleCode === 'CLI') {
              props.login (userResult.user);
            } else {
              // list companies for admin
              getCompanies ().then (companies => {
                setCompanyList (companies);
              });
            }
            setSubmitting (false);
          })
          .catch (error => {
            const appErrorCode = logMsg (error);
            if (
              error.code &&
              (error.code === 'auth/user-not-found' ||
                error.code === 'auth/wrong-password')
            ) {
              errorToast ('User or password is not correct. Please try again.');
            } else {
              errorToast ('Something went wrong. Trace ID: ' + appErrorCode);
            }
            disableLoading ();
            setSubmitting (false);
            setStatus (
              intl.formatMessage ({
                id: 'AUTH.VALIDATION.INVALID_LOGIN',
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="login-logo pb-xl-20 pb-15">
        <img src={'/images/LOGOCOLOR.png'} className="max-h-150px" alt="" />
      </div>
      <div className="mb-2">
        <p className="font-size-h4 font-weight-bold">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          Sign in to PR Analytics
        </p> {/* <p className="text-muted font-weight-bold">
          Enter your username and password
        </p> */}
      </div>

      {/*begin::Form*/} <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status
          ? <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>

            </div>
          : null}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses ('email')}`}
            name="email"
            {...formik.getFieldProps ('email')}
          />

          {formik.touched.email && formik.errors.email
            ? <div className="fv-plugins-message-container">
                <div className="fv-help-block"> {formik.errors.email} </div>

              </div>
            : null}
        </div> <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses ('password')}`}
            name="password"
            {...formik.getFieldProps ('password')}
          />

          {formik.touched.password && formik.errors.password
            ? <div className="fv-plugins-message-container">
                <div className="fv-help-block"> {formik.errors.password} </div>

              </div>
            : null}
        </div> <div className="form-group fv-plugins-icon-container">
          {user && user.userRoleCode !== 'CLI'
            ? <SingleSelect
                valueName="company"
                value={selectedCompanyId}
                onChange={(valueName, value) => {
                  setSelectedCompanyId (value);
                }}
                onBlur={() => null}
                options={getSelectList (
                  companyList,
                  'companyId',
                  'companyName'
                )}
              />
            : null}
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>

          {// already logged in as admin
          user && user.userRoleCode !== 'CLI'
            ? <div>
                <button
                  type="button"
                  className={`btn btn-default font-weight-bold px-9 py-4 my-3 mr-2`}
                  onClick={() => setUser (null)}
                >
                  <span> Cancel </span>
                </button> <button
                  type="button"
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                  style={{ backgroundColor: "#06768a", borderColor: "#06768a" }}
                  disabled={!selectedCompanyId}
                  onClick={async () => {
                    // select company and add to user state
                    const selectedCompany = companyList.find (
                      ({companyId}) => companyId === selectedCompanyId
                    );
                    if (!selectedCompany) {
                      errorToast ('Cannot find the selected company.');
                      return;
                    }
                    user.companyImage = selectedCompany.companyImage;
                    user.companyId = selectedCompany.companyId;
                    user.companyStringId = selectedCompany.companyStringId;
                    user.companyName = selectedCompany.companyName;
                    const projects = await getProjects ();
                    user.projectStringId = projects.find (
                      project => project.companyId === user.companyId
                    ).projectStringId;
                    await setUser (user);
                    props.login (user);
                  }}
                >
                  <span> Select </span>
                </button>
              </div>
            : <button
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                style={{ backgroundColor: "#06768a", borderColor: "#06768a" }}
              >
                <span> Sign In </span>

                {loading && <span className="ml-3 spinner spinner-white" />}

              </button>}
        </div>
      </form> {/*end::Form*/}
    </div>
  );
}

export default injectIntl (connect (null, auth.actions) (Form));
