import React from 'react';

const SentimentLabel = ({ sentimentName }) => {
  let style = {};
  switch (sentimentName) {
    case 'Positive':
      style.color = 'sentiment-positive-bg';
      break;
    case 'Normal':
      style.color = 'sentiment-normal-bg';
      break;
    case 'Negative':
      style.color = 'sentiment-negative-bg';
      break;
    default:
      style.color = 'sentiment-positive-bg';
      break;
  }
  return (
    <div className="text-center">
      <span
        className={`label label-lg ${style.color} label-inline rounded font-weight-bold py-4`}
      >
        {sentimentName}
      </span>
    </div>
  );
};

export default SentimentLabel;